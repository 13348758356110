import { SimpleAppState, DBA7Session } from '../interfaces';
import { APP_ID } from '../global_/config_';

export class AppState {
  private sas: SimpleAppState = {};
  private session: DBA7Session = {};

  getState(): SimpleAppState {
    return this.sas;
  }

  setState(newState: SimpleAppState) {
    this.sas = {...this.sas, ...newState };
  }

  getSession(): DBA7Session {
    this.session = JSON.parse(localStorage.getItem(APP_ID));
    return this.session;
  }

  getSessionItem(itemKey: string ): any {
    if( this.getSession() ) {
      return this.getSession()[itemKey];
    }
  }

  setSession(newSession: DBA7Session) {
    const savedSession = JSON.parse(localStorage.getItem(APP_ID));
    this.session = { ...this.session, ...savedSession, ...newSession };
    localStorage.setItem(APP_ID, JSON.stringify(this.session));

  }

  setSessionItem(itemKey: string, value: any) {
    this.setSession({[itemKey]:value});

  }
}