import { AppInstallPromptResult } from "../interfaces";
import { appState } from './app';

type Env = "iphone" | "ipad" | "mac" | "notSafari" |// safari
  "msie" | // internet explorer
  "edge" | "edge native" | // edge
  "samsung" | "samsung native" | // samsung browser
  "android" | "android native" | // android browser
  "firefox" | 
  "opera" |
  "chrome" | "chrome native" |
  "unknown"
  ;

/*
IMPROVE using userAgent may not be best solution for determining browser. navigator.vendor|platform are deprecated, may NOT work in future

On desktop:
Firefox and Safari do not support installing PWAs on any desktop operating systems.
Chrome and Edge support installing PWAs on Linux, Windows, macOS, and Chromebooks.
Safari for desktop and mobile, and Edge for desktop support installing any website as an app.

On mobile:
On Android, Firefox, Chrome, Edge, Opera, and Samsung Internet Browser all support installing PWAs.
On iOS <= 16.3, PWAs can only be installed with Safari.
On iOS >= 16.4, PWAs can be installed from the Share menu in Safari, Chrome, Edge, Firefox, and Orion.

once user has finished signing up, in app-root at OS.ONBOARD claim, user is presented with 
opportunity to install app via the only entry point call appInstallSrv.showInstallPrompt();
app-root has an instance of this class and thru its constructor, environment is determined.
showInstallPrompt checks canPrompt() 2 day interval and other cases.
Depending on the enveironment, the following can occur.
present custom install prompt and either show native install prompt from captured event
or simply show install instructions.


Browsers supporting onbeforeinstallprompt:
  Chrome, Edge, Opera, Chrome Android, Samsung Internet, Opera Android, WebView Android
  handle onbeforeinstallprompt save the native event
  show our custom prompt 
  call prompt() from event

Browsers NOT supporting onbeforeinstallprompt:
  Firefox, Safari, Firefox Android, Safari on iOS
  show isntructions from helpers
*/


// time between showing install prompt if user has not installed app
const TIME_BETWEEN_PROMPTS = 1000/*milliseconds*/ * 60/*seconds*/ * 60/*60minutes*/ * 24/*24hours*/ * 2/*days*/; // 2 days
//const TIME_BETWEEN_PROMPTS = 1000/*milliseconds*/ * 10/*seconds*/ * 1/*60minutes*/ * 1/*24hours*/ * 1/*days*/; // 10 secs for testing

/**
 * Service that checks for whether app has been installed. Uses the delay 
 * above for time between asking to install. We initiate this from user's
 * home page by calling the entry point function showInstallPrompt.
 * It will decide if we can show prompt and then present a native prompt
 * or helper screens for different environments. We rely on a session
 * item that is handled by appState component. It might be better to switch
 * to indexedDb later, if we are having problems with localStorage
 * being deleted.
 * 
 */
export class AppInstallSrv {
  currentEnv: Env = null;
  deferredEvent: any = null;
  
  constructor() {
    // parse ua
    this.setCurrentEnv();
    
    // add listeners if supported
    if( 'onbeforeinstallprompt' in window ) {
      window.addEventListener('beforeinstallprompt', this.onBeforeInstallPrompt.bind(this));
    }
    if( 'onappinstalled' in window ) {
      window.addEventListener('appinstalled', this.onAppInstalled.bind(this));
    }
  }

  setCurrentEnv() {
    const ua = window.navigator.userAgent;
    const vndr = window.navigator && window.navigator.vendor ? window.navigator.vendor : null;
    const platform = window.navigator && window.navigator.platform ? window.navigator.platform: null;
    
    // is apple device
    const isAppleDevice =  ( /iphone|ipod|ipad/i ).test(ua) || ( /mac/i.test(platform));
    if( isAppleDevice ) {
      // iphone
      if( ua.indexOf('iPhone') > -1 ) {
        this.currentEnv = "iphone";
      }
      // ipad
      else if( ua.indexOf('ipad') > -1 ) {
        this.currentEnv = "ipad";
      }
      // mac
      else if( /^((?!iphone)(?!ipad).)*mac.*$/igm.test(ua) ) {
        this.currentEnv = "mac";
      }
      // using safari?, as of 16.4 iOS allows other browsers to install pwa's
      const iOS_version_match = ua.match(/iPhone\sOS\s(\d+).(\d+)/); 
      const iOS_version_major = parseInt(iOS_version_match[1]);
      const iOS_version_minor = parseInt(iOS_version_match[2]);
      const is_iOS_version_gt_16_4 = iOS_version_major >= 16 && iOS_version_minor >= 4;
      const isSafari = vndr && vndr.indexOf('Apple') > -1 
        //&& /version\/\d+.+?safari/i.test(ua) // version seems to only be available in Safari ua's, so to allow others, don't check it
        && ( is_iOS_version_gt_16_4 || !/CriOS/i.test(ua) )  // Chrome on iOS
        && ( is_iOS_version_gt_16_4 || !/FxiOS/i.test(ua) ); // Firefox on iOS
      // safari on ios < 16.4 is the only way to install pwa, so change env
      if( !isSafari) {
        this.currentEnv = "notSafari";
      }
    }

    // windows ie before others
    else if(/(?:msie |trident.+?; rv:)\d+/i.test(ua)){
      this.currentEnv = "msie";
    }
    // edge browser, native on android
    else if( /edge\/\d+/i.test(ua) ){
      this.currentEnv = "edge";
    }
    // samsung browser, native but can't control
    else if( /SamsungBrowser/i.test(ua) ) {
      this.currentEnv = "samsung";
    }
    // android browser
    else if( /android.+version\/([\w\.]+)\s+(?:mobile\s?safari|safari)*/i.test(ua) ) {
      this.currentEnv = "android";
    }
    // firefox browser not on ios or windows
    else if( /firefox\/[\w\.-]+$/i.test(ua) ) {
      this.currentEnv = "firefox";
    }
    // opera browser not on ios or windows, native but can't control
    else if( /(?:^opera.+?version|opr|opt)\/\d+/i.test(ua) ) {
      this.currentEnv = "opera";
    }
    // chrome 
    else if( /google/i.test(vndr) && /(?:chrome)\/\d+/i.test(ua) ) {
      this.currentEnv = "chrome";
    }
    else {
      this.currentEnv = "unknown";
    }
    // eliminate desktop envs except chrome and safari
    if ( !/chrome|mac/i.test(this.currentEnv) 
      && !/mobile/i.test(ua) ){
      this.currentEnv = "unknown";
    }

    // append native if supported limit to chrome and edge
    if( "onbeforeinstallprompt" in window && /chrome|edge/i.test(this.currentEnv) ) {
      this.currentEnv += " native";
    }
  }

  /**handle native install event, save for later */
  onBeforeInstallPrompt(event) {
    // stop native install from showing now
    event.preventDefault();
    // save event, we will show later
    this.deferredEvent = event;
  }

  /**handle installed app */
  onAppInstalled(event) {
    console.log("installed");
    // appState.setSessionItem("added", true);
  }

  canPrompt(): boolean {
    // check the following cases, if true, return falsle
    // if( appState.getSessionItem("added") === true ) { return false; }
    if( /msie/.test(this.currentEnv) ) { return false;}
    if( /mac/.test(this.currentEnv) ) { return false;}
    if( /android/.test(this.currentEnv) ) { return false;}
    if( /unknown/.test(this.currentEnv) ) { return false;}
    // if( window.navigator['standalone'] === true ) { appState.setSessionItem("added", true); return false;}
    // if( window.matchMedia( '(display-mode: standalone)' ).matches ) { appState.setSessionItem("added", true); return false;}
    // if (document.referrer.includes('android-app://') ) { appState.setSessionItem("added", true); return false;}
    // if( appState.getSessionItem("lastDisplayTime") && Date.now() - appState.getSessionItem("lastDisplayTime") < TIME_BETWEEN_PROMPTS) { return false; }
    // Is it already installed?
    if( window.navigator['standalone'] === true ) { return false;}
    if( window.matchMedia( '(display-mode: standalone)' ).matches ) { return false;}
    if (document.referrer.includes('android-app://') ) { return false;}
    if( appState.getSessionItem("lastDisplayTime") && Date.now() - appState.getSessionItem("lastDisplayTime") < TIME_BETWEEN_PROMPTS) { return false; }
    // if we get to here, we can show prompt
    return true;
  }

  /**
   * Entry point to initiate install process
   */
  async showInstallPrompt() {
    // check for existing app-install and don't show another
    const isAppInstallShowing = document.querySelector("app-install");
    if( isAppInstallShowing != null ) return;
    if ( !this.canPrompt() ) return;
    // show prompt to install app
    const appInstall = document.createElement('app-install');
    appInstall.currentEnv = this.currentEnv;
    document.body.appendChild(appInstall);
    appInstall.showPrompt();
    try {
      const { userChoice }: AppInstallPromptResult = await appInstall.onDidDismissPrompt();
      // set last display time irregardless of choice
      appState.setSessionItem("lastDisplayTime", Date.now())
      // user dismissed prompt
      if( !userChoice ){
        return;
      } 
      // handle native prompt for chrome or edge
      if( /native/.test(this.currentEnv) && /chrome|edge/.test(this.currentEnv)  ) {
        // show native install prompt 
        await this.deferredEvent.prompt() 
        // wait for user to choose
        const choice = await this.deferredEvent.userChoice;
        this.deferredEvent = null;
        if( choice.outcome === 'accepted'/* or dismissed*/ ) {
          // appState.setSessionItem("added", true)
          appInstall.destroyCmp();
        }
        else {
          // if user canceled native prompt, need to destroy backdrop overlay
          appInstall.destroyCmp();
        }
      }
      // show instructions for all other cases
      else {
        appInstall.showHelper();
      }
    }
    catch(error) {
      appInstall.destroyCmp();
      console.log("Failure in showInstallPrompt:" + error.message);
    }
  }
} 