import { Plaid } from 'plaid-link';
// shared (copied) file with doneeapp, import app id from unshared config_.ts
import { FIREBASE_APP_ID, WEBAPP_OR_DONEEAPP_URL } from '../global_/config_';

export const API_ENDPOINT = process.env.API_ENDPOINT;
export const USE_EMULATORS = process.env.USE_EMULATORS;
// app url could be either for doneeapp or webapp
export const APP_URL = WEBAPP_OR_DONEEAPP_URL;

export const FIREBASE_CONFIG = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  // could be webapp id or doneeapp id
  appId: FIREBASE_APP_ID
};
export const PLAID_CONFIG: Plaid.CreateConfig = {
  clientName: "Daily Bread",
  product: ["auth","transactions"],
  key: "c4444f65265d14a0dd43df3162d7f9",
  env: process.env.PLAID_ENV as "sandbox" | "development" | "production",
  onSuccess: null,
  onExit: null,
  onEvent: null,
  onLoad: null,
  language: "en",
  //webhook: "https://requestb.in",
  //token: "", // launches in update mode, cannot be empty string
  //isWebview: false // link-initialize says this is not a valid Create param
};

