import '@ionic/core';
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { AppState } from './app-state';
import { FIREBASE_CONFIG, USE_EMULATORS } from './config';
import { AppInstallSrv } from './app-install-srv';
import { getFirestore } from 'firebase/firestore';

// create firebase app
export const fbApp = initializeApp(FIREBASE_CONFIG);
// create firestore instance
export const fbStore = getFirestore(fbApp);

/* 
Conditionally, run auth, firestore from auth, firestore emulators, uses default app which is dev version.
Frontend code will use the auth emulator and locally stored data under functions/emulator_data.
Must uncomment USE_EMULATORS=1 in .env file
*/ 
if( (location.hostname === "127.0.0.1" || location.hostname === "localhost") && USE_EMULATORS == "1") {
  // connect auth emulator
  const connectAuthEmulator = (await import("firebase/auth")).connectAuthEmulator;
  connectAuthEmulator(getAuth(fbApp), "http://127.0.0.1:9099");
  FIREBASE_CONFIG.apiKey="fake-api-key";// for getting search params from oob url
  // connect firestore emulator 
  const connectFirestoreEmulator = (await import("firebase/firestore")).connectFirestoreEmulator;
  connectFirestoreEmulator(fbStore, 'localhost', 8080);
  console.log("Emulators Auth, Firestore requests fulfilled");
}


export const fbAuth = getAuth(fbApp);
export const appState = new AppState();
export const appInstallSrv = new AppInstallSrv();




// import { connectAuthEmulator } from "firebase/auth";
// connectAuthEmulator(getAuth(fbApp), "http://127.0.0.1:9099");
// FIREBASE_CONFIG.apiKey="fake-api-key";// for getting search params from oob url
/* end comment for no auth emulator*/